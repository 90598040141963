export default [
  {
    path: '/apply',
    name: 'apply',
    component: () => import('@/views/admission/apply'),
    meta: {
      layout: 'admission',
      resource: 'Auth',
      action: 'read',
      public: true,
      admission: true,
    },
    redirect: { name: 'apply-home' },
    children: [
      {
        path: '',
        name: 'apply-home',
        component: () => import('@/views/admission/apply/components/apply'),
        meta: {
          layout: 'admission',
          resource: 'Auth',
          action: 'read',
          public: true,
          admission: true,
        },
      },
      {
        path: ':type',
        name: 'apply-types',
        component: () => import('@/views/admission/apply/components/types.vue'),
        meta: {
          layout: 'admission',
          resource: 'Auth',
          action: 'read',
          public: true,
          admission: true,
        },
      },
      {
        path: ':type/:id',
        name: 'apply-form',
        component: () => import('@/views/admission/apply/form.vue'),
        meta: {
          layout: 'admission',
          resource: 'Auth',
          action: 'read',
          public: true,
          admission: true,
        },
        children: [
          {
            path: ':success',
            name: 'apply-success',
            component: () => import('@/views/admission/apply/components/success.vue'),
            meta: {
              layout: 'admission',
              resource: 'Auth',
              action: 'read',
              public: true,
              admission: true,
            },
          },
        ],
      },
    ],
  },

  {
    path: '/apply-staff',
    name: 'apply-staff',
    component: () => import('@/views/admission/apply'),
    meta: {
      layout: 'admission',
      resource: 'Auth',
      action: 'read',
      public: true,
      admission: true,
    },
    redirect: { name: 'apply-staff-home' },
    children: [
      {
        path: '',
        name: 'apply-staff-home',
        component: () => import('@/views/admission/apply/components/apply'),
        meta: {
          layout: 'admission',
          resource: 'Auth',
          action: 'read',
          public: true,
          admission: true,
        },
      },
      {
        path: ':type',
        name: 'apply-staff-types',
        component: () => import('@/views/admission/apply/components/types.vue'),
        meta: {
          layout: 'admission',
          resource: 'Auth',
          action: 'read',
          public: true,
          admission: true,
        },
      },
      {
        path: ':type/:id',
        name: 'apply-staff-form',
        component: () => import('@/views/admission/apply/form.vue'),
        meta: {
          layout: 'admission',
          resource: 'Auth',
          action: 'read',
          public: true,
          admission: true,
        },
        children: [
          {
            path: ':success',
            name: 'apply-staff-success',
            component: () => import('@/views/admission/apply/components/success.vue'),
            meta: {
              layout: 'admission',
              resource: 'Auth',
              action: 'read',
              public: true,
              admission: true,
            },
          },
        ],
      },
    ],
  },

  {
    path: '/applyForm/success/:type/:msg',
    name: 'applyForm-success',
    permission: 'public',
    component: () => import('@/views/admission/applicant_information/components/success.vue'),
    meta: {
    },
  },
]
